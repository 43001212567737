.vertical-timeline {
    &:before {
        background: $light!important;
        width: 2px;
    }
    &-element {
        &-icon {
            background: $dark!important;
            box-shadow: 0 0 0 3px $accent, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05);
            svg {
                color: $light!important;
            }
        }
        &-date {
            color: $accent;
        }
        &-content {
            background-color: $dark;
            border-radius: 0px;
            border: 1px solid $accent;
            padding: 15px!important;
            box-shadow: none;
            &-arrow {
                border-right: 7px solid $accent!important;
            }
            h4 {
                font-size: 20px;
                color: $light;
                margin-bottom: 5px;
            }
            h5 {
                color: $accent;
                text-transform: uppercase;
            }
            p {
                font-size: 12px;
                color: $light;
                font-weight: 300;
            }
        }
        &-image {
            height: 40px;
            width: auto;
            position: absolute;
            right: 15px;
            top: 15px;
        }
        &-title,
        &-subtitle {
            padding-right: 100px;
        }
    }
}