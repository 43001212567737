.CookieConsent {
    background-color: $dark!important;
    border-top: 5px solid $accent;
    color: $light;
    box-shadow: 0px 8px 18px -2px rgba($black ,0.3);
    -webkit-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
    -moz-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
    display: flex;
    align-content: center!important;
    align-items: center!important;
    justify-content: center!important;
    button {
        background-color: $primary!important;
        color: $light!important;
        padding: 15px 0px !important;
        margin: 15px 0px!important;
        width: 100%;
        transition: all ease .3s;
        &:hover {
            background-color: $accent!important;
        }
    }
    div:first-child {
        padding: 0 15px!important;
        text-align: center;
        margin: 15px 0px!important;
        @media (min-width: $breakpoint-md) {
            text-align: left;
            padding: 0!important;
            min-width: 1120px!important;
            max-width: 1120px!important;
            padding-left: 12px!important;
        }
    }
    div:last-child {
        padding: 0!important;
        width: 100%;
        margin: 0 15px!important;
        @media (min-width: $breakpoint-md) {
            margin: 0!important;
            min-width: 200px!important;
            max-width: 200px!important;
            padding-right: 12px!important;
        }
    }
}