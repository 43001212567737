.footer {
    text-align: center;
    color: $light;
    background-color: $darkest;
    padding: 30px;
    border-top: 5px solid $accent;
    &__socials {
        margin-bottom: 30px;
    }
    &__social {
        position: relative;
        display: inline-block;
        padding: 5px 15px;
        svg {
            transform: translateY(0px);
            transition: all ease .3s;
            &:hover {
                color: $accent !important;
                transform: translateY(-5px);
            }
        }
    }
    &__copyright {
        margin-bottom: 0px;
    }
}