.button {
    border: 4px solid $primary;
    color: $light;
    border-radius: 0;
    padding: 15px 20px;
    transition: all ease .3s;

    &:hover {
        background-color: $accent;
    }

    &--primary {
        color: $light;
        background-color: rgba($dark, .9);
    }
    &--secondary {
        background-color: $primary;
    }
}

.home {
    &-buttons {
        margin-top: 30px;
        .button {
            width: 250px;
            font-size: 20px;
            font-weight: 300;
            @media (max-width: $breakpoint-sm) {
                width: 100%;
                margin-bottom: 30px;
            }
        }
        @media (min-width: $breakpoint-sm) {
            display: flex;
            justify-content: center;
            gap: 30px;
        }
    }
    &-arrow{
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        span{
            display: block;
            width: 15px;
            height: 15px;
            border-bottom: 3px solid $accent;
            border-right: 3px solid $accent;
            transform: rotate(45deg);
            margin: 5px;
            animation: animate 2s infinite;
        }
    }
}

@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-10px,-10px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(10px,10px);
    }
}