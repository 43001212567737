.language-switch {
    &__toggle {
        @media (max-width: $breakpoint-lg) {
            margin-left: 15px;
            margin-right: 15px;
        }
        .btn-primary {
            background-color: $accent;
            border-radius: 0px;
            border: none;
            &:focus {
                background-color: $accent;
            }
        }
        .dropdown-toggle {
            &:after {
                transform: rotate(0deg);
                margin-left: 10px;
            }
            &.show {
                &:after {
                    transform: rotate(180deg);
                    transition: all ease .3s
                }
            }
            @media (max-width: $breakpoint-sm) {
                margin-top: 30px;
            }
        }
        .dropdown-menu {
            background-color: $secondary;
            border-radius: 0px;
            border: none;
            color: $light;
            width: 20px!important;
            box-shadow: 0px 8px 18px -2px rgba($black ,0.3);
            -webkit-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
            -moz-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
            min-width: 50px;
            @media (max-width: $breakpoint-sm) {
                position: absolute;
                margin-left: calc(50% - 25px);
            }
            .dropdown-item {
                color: $white;
                &:hover {
                    color: $accent;
                    background-color: $secondary;
                }
            }
        }
    }
}