h1 {

}

h2 {
}

h3 {
    margin-bottom: 50px;
}

h4 {
    color: $light;
}

h5 {
    color: $primary;
    font-size: 14px;
}

a {
    color: $primary;
    text-decoration: none;
    transition: all ease .3s;
    &:hover {
        color: $accent;
    }
}

.text-light {
    color: $light;
}

.text-accent {
    color: $accent;
}

.header {
    &--hello {
        color: $light;
        opacity: 0.9;
        font-size: 150px;
        text-shadow: 1px 1px 2px $dark;
        text-align: center;
        width: 100%;
        margin-bottom: 0px;
        line-height: 130px;
        @media (max-width: $breakpoint-sm) {
            font-size: 130px;
        }
    }
    &--greet {
        opacity: 0.9;
        font-size: 50px;
        text-shadow: 1px 1px 2px $dark;
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
        @media (max-width: $breakpoint-sm) {
            font-size: 44px;
        }
    }
    &--intro {
        font-size: 18px;
        font-weight: 100;
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
        color: $light;
    }
    &--skill {
        text-transform: uppercase;
        color: $light;
        position: relative;
        padding-bottom: 30px;
        font-size: 16px;
        &:after {
            content:'';
            width: 70px;
            height: 3px;
            display: block;
            position: absolute;
            bottom: 15px;
            left: 0;
            background-color: white;
        }
    }
}
