body {
  scroll-behavior: smooth;
}

::-moz-selection { 
  color: $white;
  background: $accent;
}

::selection {
  color: $white;
  background: $accent;
}

.section {
  &:first-of-type {
    min-height: 100vh;
  }
  padding-top: 100px;
  scroll-margin-top: 30px;
  color: $light;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:nth-of-type(even) {
    background-color: $darker;
  }
  &:nth-of-type(odd) {
    background-color: $dark;
  }
  &--home {
    background-image: url('./../img/bg/bg-wooden.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    &:after {
      content: '';
      background-color: $dark;
      opacity: 80%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .container {
      position: relative;
      z-index: 2;
    }
  }
  &--about {
    p {
      font-size: 16px;
      font-weight: 200;
    }
  }
  &--privacy-policy {
    p {
      font-size: 14px;
      font-weight: 200;
    }
    h5 {
      font-size: 15px;
      font-weight: 500;
    }
  }
  @media (max-width: $breakpoint-lg) {
    scroll-margin-top: 0px;
  }
}