.scroll-to-top {
    position: fixed;
    right: 25px;
    bottom: 25px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100;
    transition: all ease .3s;
    z-index: 10;
    &:hover {
        background-color: $accent;
    }
    &--hidden {
        opacity: 0;
    }
}