.image {
    &--bordered {
        position: relative;
        padding: 0;
        margin-right: 15px;
        @media (max-width: $breakpoint-lg) {
            margin-bottom: 60px;
            max-width: 150px;
            margin: 0 auto 60px auto;
        }
        img {
            position: relative;
            z-index: 2;
            height: 100%;
            width: 100%;
            box-shadow: 0px 8px 18px -2px rgba($black ,0.3);
            -webkit-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
            -moz-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
            transition: all ease .5s;
            margin-left: -5px;
            filter: grayscale(100);
            &:hover {
                filter: grayscale(0);
            }
        }
        &:after {
            content:'';
            display: block;
            position: absolute;
            top: 20px;
            left: 15px;
            height: 100%;
            width: 100%;
            border: 5px solid $accent;
            z-index: 1;
            @media (max-width: $breakpoint-lg) {
                top: 15px;
                left: 15px;
                border: 4px solid $accent;
            }
        }
    }
}