.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $dark;
    padding: 0;
    border-bottom: 5px solid $accent;
    box-shadow: 0px 8px 18px -2px rgba($black ,0.3);
    -webkit-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
    -moz-box-shadow: 0px 8px 18px -2px rgba($black, 0.3);
    z-index: 999;
    @media (min-width: $breakpoint-lg) {
        height: 70px;
    }
    &__container {
        @media (max-width: $breakpoint-lg) {
            padding: 0;
            max-width: unset;
        }
        @media (min-width: $breakpoint-lg) {
            position: relative;
        }
    }
    &__logo {
        height: 40px;
        padding: 0 5px 5px 5px;
        @media (min-width: $breakpoint-lg) {
            height: 85px;
            margin-bottom: -25px;
        }
    }
    &__brand {
        font-size: 40px;
        margin: 0px 0 0px calc(50% - 20px);
        max-height: 50px;
        margin-top: -20px;
        color: $accent;
        &:hover {
            color: $accent;
        }
        @media (min-width: $breakpoint-lg) {
            bottom: 0;
            left: 0;
            position: absolute;
            height: 40px;
            font-size: 50px;
            display: flex;
            align-items: center;
            margin: 0;
            padding-left: 12px;
        }
        span {
            @media (min-width: $breakpoint-lg) {
                max-height: 40px;
            }
            &.dot {
                color: $light;
            }
        }
    }
    &__collapse {
        margin-left: 0;
        margin-right: 0;
        @media (min-width: $breakpoint-lg) {
            padding-left: 40px;
            justify-content: right;
            margin-right: 30px;
            opacity: 100;
        }
    }
    &__link {
        color: $light;
        text-transform: uppercase;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 45px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $accent;
            clear: both;
            margin-left: -10px;
            opacity: 0;
            transition: all ease-in .1s;
            @media (max-width: $breakpoint-lg) {
                transform: rotate(90deg);
                left: 25%;
                top: 50%;
                margin-top: -7px;
                margin-left: 0;
            }
            
        }
        @media (max-width: $breakpoint-lg) {
            margin-left: 15px;
            margin-right: 15px;
        }
        @media (min-width: $breakpoint-lg) {
            padding: 10px 15px;
        }
        &:hover {
            color: $accent;
        }
        &--active {
            font-weight: 400;
            &:after {
                content: '';
                opacity: 100%;
            }
        }
    }
    &__toggle {
        border: none;
        padding: 10px;
        border-radius: 0px;
        background-color: $accent;
        &:focus {
            border: none;
            box-shadow: none;
        }
    }
    .navbar-nav {
        .active {
            color: $light;
        }
        @media (max-width: $breakpoint-lg) {
            height: 100vh;
            width: 100vw;
            justify-content: center;
            text-align: center;
        }
    }
    &__socials {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;
        flex-wrap: wrap;
    }
    &__social {
        position: relative;
        display: inline-block;
        padding: 5px 15px;
        transform: translateY(0px);
        transition: all ease .4s;
        &:hover {
            transform: translateY(-2px);
            svg {
                transition: all ease .5s;
                color: $accent !important;
            }
        }
    }
}
