// Application breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400p;

// Colors
$primary: #61892F;
$secondary: #222629;
$accent: #86C232;
$accent-red: #C21807;
$light: #F2F4F3;
$light-darker: #C1C1C1;
$dark: #222629;
$darker: #191B1D;
$darkest: #111213;
$black: #000000;
$white: #FFFFFF;