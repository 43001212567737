.contact {
    display: flex;
    flex-direction: column;

    &--inactive {
        opacity: 0.7;
        pointer-events: none;
    }

    &__notification {
        padding: 10px;
        margin-bottom: 30px;
        p {
            margin-bottom: 0px;
            span {
                text-decoration: underline;
                cursor: pointer;
                transition: all ease .3s;
                &:hover {
                    color: $accent;
                }
            }
        }
        &--success {
            border: 1px solid $accent;
        }
        &--warning {
            border: 1px solid $accent-red;
        }
    }

    label {
        margin-bottom: 10px;
    }

    input, textarea {
        background-color: $darker;
        border: 1px solid $darker;
        margin-bottom: 30px;
        padding: 5px 15px;
        caret-color: $light;
        color: $light;
        transition: all ease .3s;
        width: 100%;
        &:focus, &:active, &:focus-visible {
            border: 1px solid $accent;
            box-shadow: none;
            outline: none;
        }
    }
    textarea {
        min-height: 150px;
    }
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px $dark inset !important;
        -webkit-text-fill-color: $light !important;
    }
    button {
        width: fit-content;
        padding: 5px 15px;
        background-color: $primary;
        color: $light;
        border: none;
        border: 3px solid $primary;
        transition: all ease .3s;
        @media (max-width: $breakpoint-sm) {
            margin: 0 auto;
        }
        &:hover {
            color: $light;
            background-color: $accent;
            border: 3px solid $accent;
        }
    }
}